import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { LoginService } from './login.service';
import { Storage } from '@ionic/storage';
import { FunctionService } from '../shared/services/function.service';
import jwt_decode from "jwt-decode";
import { ModalController } from '@ionic/angular';
import { PasswordResetViewComponent } from './password-reset-view/password-reset-view.component';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  canLogIn: boolean= true;
  showError: boolean = false;
  
  constructor(private fb: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private storage: Storage,
    private functionService: FunctionService,
    private modalCtrl: ModalController) { }

  ngOnInit(): void {
    sessionStorage.removeItem("token")
    $(function(){
      $('.hide-show').show();
      $('.hide-show span').addClass('show')
      
      $('.hide-show span').click(function(){
        if( $(this).hasClass('show') ) {
          $(this).text('Hide');
          $('input[name="login[password]"]').attr('type','text');
          $(this).removeClass('show');
        } else {
           $(this).text('Show');
           $('input[name="login[password]"]').attr('type','password');
           $(this).addClass('show');
        }
      });
      
      $('form button[type="submit"]').on('click', function(){
        $('.hide-show span').text('Show').addClass('show');
        $('.hide-show').parent().find('input[name="login[password]"]').attr('type','password');
      }); 
    });

    this.loginForm = this.fb.group({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(3)])),
    });
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('token');

  }

  async login(user: any) {
    await this.functionService.presentLoading("Anmeldung...");
    this.loginService.login(user).subscribe(tokenObject=>{
      this.storage.set('currentUser', user);
      sessionStorage.setItem('token', tokenObject.token);
      this.functionService.hideLoading();
      this.loginForm.reset();
      var token = sessionStorage.getItem('token');
      var decoded = jwt_decode(token);
      if(decoded["role"]=="Administrator"){
        this.router.navigate(['/home/tabs/tab4']);
      } else{
        this.router.navigate(['/home/tabs/tab1']);
      }
    }, async error=>{
      await this.functionService.hideLoading();
      this.showError= true;
    })
  }

  removeError() {
    this.showError = false;
  }

  async resetPassword(){
    const modal = await this.modalCtrl.create({
      component: PasswordResetViewComponent,
      cssClass: 'cal-modal',
      backdropDismiss: false
    });
   
    await modal.present();
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPasswordReset } from '../password-reset/password-reset-model';
import { InLogin } from './models/inLogin';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  api="https://backend.storn-gmbh.de/api";
  constructor(private httpClient: HttpClient) { }

  login(user: InLogin): Observable<any>{
    return this.httpClient.post<any>(this.api + '/Authentication/signIn', user);
  }

  resetPassword(email: string): Observable<any>{
    return this.httpClient.post<any>(this.api + `/User/password/resetToken?email=${email}`, {});
  }

  reset(data: IPasswordReset): Observable<any>{
    return this.httpClient.post<any>(this.api + `/User/password/reset`, data);
  }
  
}

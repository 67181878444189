import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FunctionService } from 'src/app/shared/services/function.service';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-password-reset-view',
  templateUrl: './password-reset-view.component.html',
  styleUrls: ['./password-reset-view.component.scss'],
})
export class PasswordResetViewComponent implements OnInit {

  email: string="";
  loading: boolean= false;
  constructor(private functionService: FunctionService,
            private loginService: LoginService,
            private modalCtrl: ModalController) { }

  ngOnInit() {}

  sendLink(){
    const emailRegex = RegExp(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
      if(emailRegex.test(this.email)){
        this.functionService.presentLoading("Bitte warten...");
        this.loginService.resetPassword(this.email).subscribe(
          data=>{
            this.functionService.presentToast("E-Mail mit einem Passwort zum Zurücksetzen wurde erfolgreich an die E-Mail-Adresse gesendet", 4000, "success", 0);
            setTimeout(() => {
              this.close();
            }, 4000);
          },
          error=>{
            this.functionService.presentToast("Etwas ist schief gelaufen", 4000, "danger", 0);
          },
          ()=>{
            this.functionService.hideLoading();
          },
        )
      } else{
        this.functionService.presentToast("Ungültiges Email-Format", 4000, "danger", 0);
        this.email="";
      }
  }

  close() {
    this.modalCtrl.dismiss();
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../login/login.service';
import { FunctionService } from '../shared/services/function.service';
import jwt_encode from "jwt-decode";
@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {

  user: string;
  token: string;

  confirmPassword: string;
  password: string;
  
  constructor(private route: ActivatedRoute,
              private router: Router,
              private service: LoginService,
              private functionService: FunctionService) { }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.user = decodeURI(params.user);
        this.token = decodeURI(params.token);
      }
    );
  }

  close() {
    this.router.navigate(["login"]);
  }

  resetPassword(){
    if(this.password && this.confirmPassword && this.password===this.confirmPassword){
      this.functionService.presentLoading("Bitte warten...");
      this.service.reset({email: this.user, password: this.password, confirmPassword:this.confirmPassword, token:this.token}).subscribe(
        (data)=>{
          this.functionService.presentToast("das Passwort wurde erfolgreich geändert", 4000, "success", 0);
          setTimeout(() => {
            this.close();
          }, 2000);
        },
        error=>{
          this.functionService.presentToast("Etwas ist schief gelaufen", 4000, "danger", 0);
          this.functionService.hideLoading();
        },
        ()=>{
          this.functionService.hideLoading();
        }
      )
    } else{
      this.functionService.presentToast("Passwörter stimmen nicht überein, geben Sie sie bitte erneut ein", 4000, "danger", 0);
      this.password="";
      this.confirmPassword="";
    }
  }

}
